import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {mediaLinks} from "./artists.module.css"

import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faSoundcloud, faTwitter, faInstagram, faBandcamp, faItunes, faTiktok } from '@fortawesome/free-brands-svg-icons'


const ArtistsPage = () => (
  <Layout disableFlexContainer={true}>
    <Seo title="Rumia" />
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={5}>
        <h2>
            RUMIA
        </h2>
      </Grid>
      <Grid item xs={12} md={5}>
        {/* Nothing */}
      </Grid>
      <Grid item xs={12} md={5}>
        <div style={{position: 'relative', overflow: 'hidden', height: '0', paddingBottom: '56.25%'}}>
          <iframe 
            title="spotify-playlist"
            style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
            src="https://open.spotify.com/embed/playlist/5d6iieNeDIUVcgwmeB0w1g?utm_source=generator"
            width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"></iframe>          
        </div>
        <div style={{marginTop: '2rem', borderBottom: 'solid 1px black'}}></div>
        <div className={mediaLinks}>
          <a href="https://facebook.com/rumiamusic" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
          </a>
          <a href="https://instagram.com/rumiamusic/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
          </a>
          <a href="https://www.tiktok.com/@iamrumia" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faTiktok}></FontAwesomeIcon>
          </a>
          
          <a href="https://soundcloud.com/rumiamusic" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faSoundcloud}></FontAwesomeIcon>
          </a>
          <a href="https://twitter.com/Rumiamusic" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
          </a>
          <a href="https://rumiamusic.bandcamp.com/releases" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faBandcamp}></FontAwesomeIcon>
          </a>
          <a href="https://music.apple.com/es/artist/rumia/1480216656?l=en" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faItunes}></FontAwesomeIcon>
          </a>
        </div>
      </Grid>


      <Grid item xs={12} md={5}>
        <div style={{position: 'relative', overflow: 'hidden', height: '0', paddingBottom: '56.25%'}}>
          <iframe 
          title="youtube-video"
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
          width="560" height="315" src="https://www.youtube.com/embed/RpuTKmJD2Qc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div style={{marginTop: '1rem'}}>
          <h4>
            BIO
          </h4>
          <p>
            Blanca Pereira, aka Rumia, is a musician based in Berlin.
          </p>
          <p>
            Moved by the Berlin music scene, her songs have evolved into synthetic and electronic sounds.
          </p>
          <p>  
            A fresh proposal influenced by genres from the 90s like Trip Hop.
          </p>
          <p>
            The lyrical content revolves around the exploration of the human being and their behaviors, both individually and collectively. Named after 'rumination', the act of thinking deeply and recursively about something.
          </p>
        </div>
      </Grid>
       
    </Grid>
  </Layout>
)

export default ArtistsPage
 